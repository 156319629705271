var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm2",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm2,
            "status-icon": "",
            rules: _vm.rules2,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "20px" },
              attrs: { label: "原密码", prop: "oldpassword" },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "new-password" },
                model: {
                  value: _vm.ruleForm2.oldpassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm2, "oldpassword", $$v)
                  },
                  expression: "ruleForm2.oldpassword",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "20px" },
              attrs: { label: "新密码", prop: "pass" },
            },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "new-password" },
                model: {
                  value: _vm.ruleForm2.pass,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm2, "pass", $$v)
                  },
                  expression: "ruleForm2.pass",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "12px", "padding-right": "20px" },
              attrs: { label: "确认密码", prop: "checkPass" },
            },
            [
              _c("el-input", {
                attrs: { type: "password", autocomplete: "new-password" },
                model: {
                  value: _vm.ruleForm2.checkPass,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm2, "checkPass", $$v)
                  },
                  expression: "ruleForm2.checkPass",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }