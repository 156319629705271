var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.user
      ? _c(
          "div",
          [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { gutter: 12, justify: "center", type: "flex" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 12, md: 12, lg: 6 } },
                  [_c("user-card", { attrs: { user: _vm.user } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 12, md: 12, lg: 6 } },
                  [
                    _c(
                      "el-card",
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "修改密码", name: "account" } },
                              [
                                _c("account", {
                                  staticStyle: { "margin-top": "20px" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }