//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UpdataoneUserList } from '@/api/updateUserInfo/Usermanagement';
export default {
  data: function data() {
    var _this = this;
    var validatePass = function validatePass(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('新密码不能少于6位'));
      } else {
        if (_this.ruleForm2.checkPass !== '') {
          _this.$refs.ruleForm2.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.ruleForm2.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm2: {
        pass: '',
        checkPass: '',
        oldpassword: ''
      },
      rules2: {
        pass: [{
          required: true,
          validator: validatePass,
          trigger: 'blur'
        }],
        checkPass: [{
          required: true,
          validator: validatePass2,
          trigger: 'blur'
        }],
        oldpassword: [{
          required: true,
          trigger: 'blur',
          message: '原密码不能为空'
        }]
      }
    };
  },
  methods: {
    LogOut: function LogOut() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
      localStorage.clear();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            old_password: _this2.ruleForm2.oldpassword,
            password: _this2.ruleForm2.pass,
            repassword: _this2.ruleForm2.checkPass
          };
          UpdataoneUserList(data).then(function (res) {
            if (res.code == 0) {
              _this2.$notify({
                title: '成功',
                message: '修改密码成功,将在三秒之后回到登陆页面',
                type: 'success',
                duration: '5000',
                onClose: _this2.LogOut
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};