//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PanThumb from '@/components/PanThumb';
import { mapGetters } from 'vuex';
export default {
  components: {
    PanThumb: PanThumb
  },
  data: function data() {
    return {
      info: {},
      img: process.env.VUE_APP_BASE_API2 + '/storage/client/direction/file/20210122/1ffe1ffcfa16db24f0b02b3203808e82.png',
      showTime: true
    };
  },
  computed: {},
  created: function created() {
    this.GetList();
  },
  methods: {
    GetList: function GetList() {
      this.info = this.$store.state.user;
    }
  }
};