//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import UserCard from "./components/UserCard";
import Activity from "./components/Activity";
import Timeline from "./components/Timeline";
import Account from "./components/Account";
export default {
  name: 'Profile',
  components: {
    UserCard: UserCard,
    Activity: Activity,
    Timeline: Timeline,
    Account: Account
  },
  data: function data() {
    return {
      user: {},
      activeTab: 'account'
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    getUser: function getUser() {
      this.user = {
        name: this.userName,
        role: this.roles.join(' | '),
        email: 'admin@test.com',
        avatar: this.avatar
      };
    }
  }
};